require("whatwg-fetch");
const uncaught = require("uncaught");

const scrollToHashAnchor = (location) => {
  if (location && location.hash) {
    try {
      const elem = document.querySelector(location.hash);
      if (!elem) return;
      setTimeout(() => window.scrollTo({ top: elem.offsetTop, left: 0 }), 0);
    } catch (error) {
      // console.log(error);
    }
  }
};

const sendErrorReport = () => {
  if (typeof window.onerror === "object") {
    // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#notes
    window.onerror = function (msg, url, lineNo, columnNo, error) {
      const description = [
        "Message: " + msg,
        "URL: " + url,
        "Line: " + lineNo,
        "Column: " + columnNo,
        "Error object: " + JSON.stringify(error),
      ].join(" - ");

      fetch(`/ping?error=${description}`, {
        credentials: "omit",
        referrerPolicy: "same-origin",
      });
    };

    return false;
  }
};

exports.onRouteUpdate = ({ location }) => {
  scrollToHashAnchor(location);
};

exports.onClientEntry = () => {
  sendErrorReport();

  uncaught.start();
  uncaught.addListener(function (error) {
    const description = `Uncaught error/rejection: ${error.message}`;
    fetch(`/ping?error=${description}`, {
      credentials: "omit",
      referrerPolicy: "same-origin",
    });
  });
};
