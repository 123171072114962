exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-author-author-template-index-tsx": () => import("./../../../src/templates/author/AuthorTemplate/index.tsx" /* webpackChunkName: "component---src-templates-author-author-template-index-tsx" */),
  "component---src-templates-content-hub-content-hub-tsx": () => import("./../../../src/templates/content-hub/ContentHub.tsx" /* webpackChunkName: "component---src-templates-content-hub-content-hub-tsx" */),
  "component---src-templates-country-selector-country-selector-js": () => import("./../../../src/templates/country-selector/CountrySelector.js" /* webpackChunkName: "component---src-templates-country-selector-country-selector-js" */),
  "component---src-templates-embedded-page-embedded-page-tsx": () => import("./../../../src/templates/embedded-page/EmbeddedPage.tsx" /* webpackChunkName: "component---src-templates-embedded-page-embedded-page-tsx" */),
  "component---src-templates-free-form-free-form-tsx": () => import("./../../../src/templates/free-form/FreeForm.tsx" /* webpackChunkName: "component---src-templates-free-form-free-form-tsx" */),
  "component---src-templates-how-to-how-to-js": () => import("./../../../src/templates/how-to/HowTo.js" /* webpackChunkName: "component---src-templates-how-to-how-to-js" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-review-review-tsx": () => import("./../../../src/templates/review/Review.tsx" /* webpackChunkName: "component---src-templates-review-review-tsx" */),
  "component---src-templates-reviewer-reviewer-tsx": () => import("./../../../src/templates/reviewer/Reviewer.tsx" /* webpackChunkName: "component---src-templates-reviewer-reviewer-tsx" */),
  "component---src-templates-sign-up-form-widget-components-sign-up-form-widget-redirect-tsx": () => import("./../../../src/templates/sign-up-form-widget/components/SignUpFormWidgetRedirect.tsx" /* webpackChunkName: "component---src-templates-sign-up-form-widget-components-sign-up-form-widget-redirect-tsx" */),
  "component---src-templates-sign-up-form-widget-components-sign-up-form-widget-tsx": () => import("./../../../src/templates/sign-up-form-widget/components/SignUpFormWidget.tsx" /* webpackChunkName: "component---src-templates-sign-up-form-widget-components-sign-up-form-widget-tsx" */),
  "component---src-templates-sitemap-sitemap-tsx": () => import("./../../../src/templates/sitemap/Sitemap.tsx" /* webpackChunkName: "component---src-templates-sitemap-sitemap-tsx" */),
  "component---src-templates-topic-topic-tsx": () => import("./../../../src/templates/topic/Topic.tsx" /* webpackChunkName: "component---src-templates-topic-topic-tsx" */)
}

